import React from "react";

const ReviewCard = ({ data }) => {
  const styleCard = {
    background: `rgba( 255, 255, 255, 0.15 )`,
    // boxShadow: `0 8px 32px 0 rgba( 31, 38, 135, 0.37 )`,
    backdropFilter: `blur( 3px )`,
    // -webkit-backdrop-filter: blur( 3px );
    borderRadius: `5px`,
  };

  return (
    <div className=" d-flex justify-content-center">
      <div
        class="card text-white  my-3"
        style={{ maxWidth: "18rem", ...styleCard }}
      >
        <div class="card-header d-flex justify-content-start">
          <img src={data.image} alt="" srcset="" />
          <div class="ms-2 me-auto">
            <div class="fw-bold">{data.name}</div>
            {data.designation}
          </div>
        </div>
        <div class="card-body" style={{ minHeight: "250px" }}>
          <p class="card-text">{data.des}</p>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
