import "./App.css";
import "./index.css";

import Home from "./components/home/Home";
import ProgramHighlight from "./components/programs/ProgramHighlight";
import Skills from "./components/skills/Skills";
import Tools from "./components/tools/Tools";
import LearningPath from "./components/learningPath/LearningPath";
import StudentReview from "./components/studentReviewCaruosel/StudentReview";
import Training from "./components/training/Training";
import WyBhangleSoftware from "./components/whyBhangleS/WyBhangleSoftware";
import GoogleForm from "./components/form/GoogleForm";
import WhatsappChatt from "./components/whatsppChatt/WhatsappChatt";
// import Objectives from "./components/objective/Objectives";

function App() {
  return (
    <div className="App">
      <Home />
      {/* <Objectives /> */}
      <ProgramHighlight />
      <Skills />
      <Tools />
      <LearningPath />
      {/* training  */}
      <Training />
      <StudentReview />
      <WyBhangleSoftware />
      <GoogleForm />
      <WhatsappChatt />
    </div>
  );
}

export default App;
