import React from "react";
import ProgramCard from "./ProgramCard";

import "aos/dist/aos.css";
import ObjectiveCard from "../objective/ObjectiveCard";

const ProgramHighlight = () => {
  // useEffect(() => {
  //   AOS.init({ duration: 1500, delay: 1000 });
  // });
  const data = [
    { name: "Industrial based Faculty", image: "./images/1.png" },
    {
      name: "Comprehensive Course Syllabus Transformation",
      image: "./images/2.png",
    },

    { name: "Lab question", image: "./images/3.png" },

    { name: "Job Readiness Guidance", image: "./images/4.png" },
  ];
  const objective = [
    {
      name: "Our Mission",
      des: "To provide IT skills development for every student and working professional and make them grow in all the sectors.",
      image: "./images/obj1.png",
    },
    {
      name: "Our Vision",
      des: "To be the world’s most IT oriented institute, where both Non IT & IT candidates can grow.",
      image: "./images/obj2.png",
    },

    {
      name: "Our Goal",
      des: "To stand out in the IT sectors & to give the best career to every student. ",
      image: "./images/obj3.png",
    },
  ];

  return (
    <div>
      <div className="container-fluid" style={{ minHeight: "300px" }}>
        <div
          className="row d-flex justify-content-center py-3 text-white"
          style={{ backgroundColor: " #38B6FF" }}
        >
          <div
            className="col-6 col-sm-3  d-flex justify-content-center align-items-center"
            data-aos="flip-right"
          >
            <div>
              <img
                width={"65px"}
                src="./images/calender.png"
                alt=""
                srcset=""
              />
            </div>
            <div className="ms-3">
              <h3>6 Months </h3>
              <p style={{ lineHeight: "0px" }}>Duration </p>
            </div>
          </div>
          <div
            className="col-6 col-sm-3  d-flex justify-content-center align-items-center"
            data-aos="flip-left"
          >
            <div>
              <img width={"65px"} src="./images/time.png" alt="" srcset="" />
            </div>
            <div className="ms-3">
              <h3>160 + Hours </h3>
              <p style={{ lineHeight: "0px" }}> of Learning </p>
            </div>
          </div>
        </div>
        {/* // Objectives */}
        <div className="row d-flex justify-content-center mt-2 ">
          {objective.map((data, i) => (
            <ObjectiveCard data={data} />
          ))}
        </div>
        {/* // ---------------- */}
        <h1
          className="text-center heading1 mt-3 "
          style={{ color: "#461D6F" }}
          data-aos="zoom-in-up"
        >
          Program Highlights
        </h1>
        <div className="row d-flex ">
          {data.map((data, i) => (
            <ProgramCard data={data} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProgramHighlight;
