import React from "react";
import CardSkills from "../skills/CardSkills";

const LearningPath = () => {
  const tools = [
    { name: "Get Trained", image: "./images/lp1.png", color: "black" },
    {
      name: "Labs Questions Support",
      image: "./images/lp2.png",
      color: "black",
    },

    {
      name: "Interview Preparation Support",
      image: "./images/lp3.png",
      color: "black",
    },

    {
      name: "Job Readiness Guidance",
      image: "./images/lp4.png",
      color: "black",
    },
  ];

  return (
    <div className="container-fluid" data-aos="fade-up">
      <div
        className="row px-3 pb-4 d-flex justify-content-center py-5"
        style={{ backgroundColor: " #FFE0B3" }}
      >
        <h1 className="text-center heading1 mt-3 " style={{ color: "black" }}>
          Learning Path
        </h1>

        <div className="row d-flex justify-content-center py-5">
          {tools.map((data, i) => (
            <CardSkills data={data} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LearningPath;
