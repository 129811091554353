import React from "react";
import CardSkills from "./CardSkills";

const Skills = () => {
  const skills = [
    { name: "Statistics", image: "./images/s1.png", color: "black" },
    {
      name: "Data Analytics",
      image: "./images/data-analitics.png",
      color: "black",
    },

    {
      name: "Data Visualization",
      image: "./images/data-visualization.png",
      color: "black",
    },

    {
      name: "Structuring",
      image: "./images/filled.png",
      color: "black",
    },
  ];

  return (
    <div className="container-fluid" data-aos="fade-up">
      <div
        className="row px-3 pb-4 mt-5 d-flex justify-content-center py-5 "
        style={{ backgroundColor: " #FFC736" }}
      >
        <h1 className="text-center heading1 mt-3 " style={{ color: "#461D6F" }}>
          Skill Covered
        </h1>

        <div className="row d-flex justify-content-center my-5 ">
          {skills.map((data, i) => (
            <CardSkills data={data} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Skills;
