import React from "react";

const ProgramCard = ({ data }) => {
  return (
    <div
      className="col-12 col-sm-6 col-md-3 mt-3 d-flex justify-content-center "
      data-aos="fade-up"
    >
      <div className="card border  " style={{ width: "100%" }}>
        <div
          className="row d-flex justify-content-center py-5 m-2 rounded"
          style={{ backgroundColor: " #E8E8E8" }}
        >
          <div style={{ width: "150px" }}>
            <img
              src={data.image}
              className=" "
              width="150px"
              height="130px"
              //   style={{  }}
              alt="..."
            />
          </div>
        </div>

        <div className="card-body">
          <p
            className="card-text"
            style={{
              fontWeight: 400,
              fontSize: "18px",
              color: "#000000",
              textAlign: "center",
            }}
          >
            {data.name}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProgramCard;
