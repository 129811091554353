import React from "react";

import WhyCard from "./WhyCard";

const WyBhangleSoftware = () => {
  const tools = [
    {
      name: (
        <p>
          <strong>Industry :</strong> Based Course Curriculum
        </p>
      ),
      image: "./images/b1.png",
      color: "black",
    },
    {
      name: (
        <p>
          <strong>Value Adds :</strong> Python Programming, Fundamentals of R,
          Business Statistics
        </p>
      ),
      image: "./images/b2.png",
      color: "black",
    },

    {
      name: (
        <p>
          <strong>Work Hands :</strong> on Lab Questions, Assignments, and get
          guidance on Interview Preparation
        </p>
      ),
      image: "./images/b3.png",
      color: "black",
    },

    {
      name: <p>Dedicated Placement Cell</p>,
      image: "./images/b4.png",
      color: "black",
    },
    {
      name: (
        <p>
          <strong>Support :</strong> WhatsApp, Calls, &amp; Emails
        </p>
      ),
      image: "./images/b5.png",
      color: "black",
    },

    {
      name: "Practice with our recorded videos &amp; notes",
      image: "./images/b6.png",
      color: "black",
    },
  ];

  return (
    <div className="container-fluid" data-aos="fade-up">
      <div
        className="row px-3 pb-4 d-flex justify-content-center py-5"
        style={{ backgroundColor: " #FAEBFF" }}
      >
        <h1 className="text-center heading1 mt-3 " style={{ color: "black" }}>
          Why Bhangale Education ?
        </h1>

        <div className="row d-flex justify-content-center py-5">
          {tools.map((data, i) => (
            <WhyCard data={data} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WyBhangleSoftware;
