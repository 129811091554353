import React from "react";

const GoogleForm = ({ showModal, handleCloseModal }) => {
  return (
    <div>
      <div
        className={`modal ${showModal ? "show" : ""}`}
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div className="modal-header">
              <img
                height={"50px"}
                src="./images/bhangleLogo.png"
                alt=""
                srcset=""
              />
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={handleCloseModal}
              ></button>
            </div>
            <div class="">
              {/* <form>
                <iframe
                  src="https://docs.google.com/forms/d/e/1FAIpQLSdlb9PP7ErfNAGzdhXuWLT3JHCpWMqy12451x0eS5MHOWIyFQ/viewform?embedded=true"
                  width="100%"
                  height="550"
                  frameborder="0"
                  marginheight="0"
                  marginwidth="0"
                >
                  Loading…
                </iframe>
              </form> */}
              <form>
                <iframe
                  src="https://docs.google.com/forms/d/e/1FAIpQLScc5u4VvsocZ4cYhKQ-ZLKAMsy-UBd_JRLYDC-QF1QMODxVrA/viewform?embedded=true"
                  width="100%"
                  height="550"
                  frameborder="0"
                  marginheight="0"
                  marginwidth="0"
                >
                  Loading…
                </iframe>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleForm;
