import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReviewCard from "./ReviewCard";

const StudentReview = () => {
  const tools = [
    {
      name: "Gazala Ansari",
      designation: "TechSolutions Inc.",
      image: "./images/tes1.png",
      des: `Bhangale Education's data analytics course surpassed expectations. Comprehensive curriculum, 
      knowledgeable instructors, practical insights.
       Developed valuable skills applied professionally. Highly recommend for aspiring data analysts`,
    },
    {
      name: "Supriya Pawar",
      designation: "Innovate Solutions",
      image: "./images/tes2.png",
      des: `Exceptional program! Bhangale Education's Data Analytics course provided hands-on training with real-world datasets. 
      The curriculum was well-structured, and the instructors were highly knowledgeable. Worth every penny`,
    },

    {
      name: "Rahul Singh",
      designation: "Dynamics Ltd.",
      image: "./images/tes3.png",
      des: `Bhangale Education's Data Analytics course exceeded my expectations. The content was up-to-date, and the hands-on 
      approach helped me grasp complex concepts easily.
       The instructors were engaging and supportive throughout the program.`,
    },
  ];

  var settings = {
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      className="container-fluid mt-5 p-4"
      style={{ backgroundColor: "#008AD7" }}
    >
      <div className="row px-1 px-sm-3 px-xl-5 pb-4 d-flex justify-content-center">
        <h1 className="text-center heading1 mt-3 " style={{ color: "white" }}>
          Students Review
        </h1>

        <div className="row d-flex justify-content-center ">
          <Slider {...settings}>
            {tools.map((data, i) => (
              <ReviewCard data={data} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default StudentReview;
