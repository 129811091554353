import React from "react";

const ObjectiveCard = ({ data }) => {
  return (
    <div
      className="col-12 col-sm-6 col-md-3 mt-3 d-flex justify-content-center "
      data-aos="fade-up"
    >
      <div className="card" style={{ width: "100%", border: "none" }}>
        <div
          className="row d-flex justify-content-center py-3 m-2 rounded"
          style={{
            background: "rgba(175, 231, 255, 0.37)",
            minHeight: "350px",
          }}
        >
          <div style={{ width: "150px" }}>
            <img
              src={data.image}
              className=" "
              width="120px"
              height="120px"
              //   style={{  }}
              alt="..."
            />
          </div>

          <div className="card-body">
            <p
              className="card-text"
              style={{
                fontWeight: 400,
                fontSize: "20px",
                color: "#000000",
                textAlign: "center",
              }}
            >
              <strong>{data.name}</strong>
            </p>
            <p
              className="card-text"
              style={{
                fontWeight: 400,
                fontSize: "18px",
                color: "#000000",
                textAlign: "center",
              }}
            >
              {data.des}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ObjectiveCard;
