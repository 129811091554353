import React from "react";

const WhatsappChatt = () => {
  return (
    <div>
      {" "}
      <div
        data-aos="zoom-in"
        style={{ position: "fixed", bottom: "10px", right: "10px" }}
      >
        {" "}
        <a href="https://wa.me/919321058822" target="_blank">
          <img
            style={{
              width: "max-content",
              backgroundColor: "#00D95F",
              borderRadius: "10px",
            }}
            className="p-2"
            height={"75px"}
            width={"75px"}
            src="./images/whatsapp.png"
            alt=""
            srcset=""
          />
        </a>
      </div>
    </div>
  );
};

export default WhatsappChatt;
