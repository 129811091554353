import React from "react";

const WhyCard = ({ data }) => {
  return (
    <div
      className="col-6 col-sm-4 col-md-2 d-flex flex-column align-items-center justify-content-center mt-4"
      data-aos="zoom-in"
    >
      <img src={data.image} width={"70px"} alt="" srcset="" />
      <p
        className="card-text"
        style={{
          fontWeight: 400,
          fontSize: "18px",
          color: `${data.color}`,
          textAlign: "center",
        }}
      >
        {data.name}
      </p>
    </div>
  );
};

export default WhyCard;
