import React from "react";
import CardSkills from "../skills/CardSkills";

const Tools = () => {
  const tools = [
    { name: "Tableau", image: "./images/tool1.png", color: "white" },
    {
      name: "My SQL",
      image: "./images/tool2.png",
      color: "white",
    },

    {
      name: "Excel Basic and Advanced",
      image: "./images/tool3.png",
      color: "white",
    },

    { name: "Power BI", image: "./images/tool4.png", color: "white" },
    { name: "Python", image: "./images/tool5.png", color: "white" },

    { name: "R Programming", image: "./images/tool6.png", color: "white" },
  ];

  return (
    <div className="container-fluid" data-aos="fade-up">
      <div
        className="row px-3 pb-4  d-flex justify-content-center py-5"
        style={{ backgroundColor: " #000F55" }}
      >
        <h1 className="text-center heading1 mt-3 " style={{ color: "white" }}>
          Tools & Techniques
        </h1>

        <div className="row d-flex justify-content-space-around py-5">
          {tools.map((data, i) => (
            <CardSkills data={data} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Tools;
