import React, { useState } from "react";
import GoogleForm from "../form/GoogleForm";

const TrainingCard = ({ data }) => {
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const styleCard = {
    boxShadow: `0 8px 32px 0 rgba( 31, 38, 135, 0.37 )`,
    backdropFilter: `blur( 3px )`,
    // -webkit-backdrop-filter: blur( 3px );
    borderRadius: `10px`,
    backgroundColor: "rgba(0, 0, 0, 0.07)",
    color: "#000000",
  };
  const textStyle1 = {
    fontSize: "24px",
    fontWeight: 600,
  };

  return (
    <>
      <div className=" d-flex justify-content-center" data-aos="flip-left">
        <div class="card  mb-3" style={{ ...styleCard }}>
          <div
            class="card-header d-flex justify-content-start"
            style={{
              backgroundColor: "rgba(250, 255, 0, 0.24)",
            }}
          >
            <div class="ms-2 me-auto">
              <h3 class="fw-bold">{data.name}</h3>
            </div>
          </div>
          <div class="card-body">
            <div class="d-flex justify-content-start">
              <p class="text-decoration-line-through" style={textStyle1}>
                {data.localPrice}
              </p>
              <p
                class="fw-bold ms-2"
                style={{ ...textStyle1, color: "#17A600" }}
              >
                {data.originalPrice}
              </p>
            </div>
            <div className="row ">
              <div className="col-12 d-flex justify-content-start align-items-center">
                <p>
                  {" "}
                  <img
                    className="me-2"
                    width={"20px"}
                    height={"20px"}
                    src="./images/iconLogo.svg"
                    alt=""
                    srcset=""
                  />
                  {data.p1}
                </p>
              </div>
              <div className="col-12 d-flex justify-content-start align-items-center">
                {" "}
                <p>
                  {" "}
                  <img
                    width={"20px"}
                    height={"20px"}
                    className="me-2"
                    src="./images/iconLogo.svg"
                    alt=""
                    srcset=""
                  />
                  {data.p2}
                </p>
              </div>
              <div className="row d-flex justify-content-center m-2 p-2 ">
                <button
                  type="button"
                  class="btn btn-primary "
                  style={{ width: "max-content" }}
                  onClick={handleOpenModal}
                >
                  ENROLL NOW
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <GoogleForm showModal={showModal} handleCloseModal={handleCloseModal} />
      )}
    </>
  );
};

export default TrainingCard;
