import React, { useEffect, useState } from "react";
import GoogleForm from "../form/GoogleForm";
import AOS from "aos";
import "aos/dist/aos.css";

const Home = () => {
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    AOS.init({ duration: 1000, delay: 200 });
  });

  const style = {
    backgroundImage: `url("./images/image1.png")`,
    height: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div>
      <div style={style} className="container-fluid ">
        <div
          className="row text-white py-3 "
          style={{
            background: `rgba( 255, 255, 255, 0.2 )`,
            boxShadow: `0 8px 32px 0 rgba( 31, 38, 135, 0.37 )`,
            backdropFilter: `blur( 2.5px )`,
            WebkitBackdropFilter: `blur( 2.5px )`,
            // borderRadius: `10px`,
            // border: `1px solid rgba( 255, 255, 255, 0.18 )`,
          }}
        >
          <div className="col-6 d-flex justify-content-start align-items-center">
            <img className="logoImg" src="./images/logo.png" alt="" srcset="" />
          </div>
          <div className="col-6 d-flex justify-content-end align-items-center">
            <img className="callImg" src="./images/call.png" alt="" srcset="" />
            <p className="mNumber"> +91 9321058822</p>
          </div>
        </div>

        <div className="row " data-aos="fade-up">
          <div
            className="col-12 col-md-8 col-xl-6   d-flex flex-column justify-content-center px-2 px-sm-5 "
            style={{ height: "90vh" }}
          >
            <h1 className="text-white heading1 ">
              Empower Your Learning Journey
            </h1>
            <h2 className="text-white heading2">
              Data Analyst Certification Course in Mumbai
            </h2>
            <p className=" text1">
              Transform your learning journey with Data Analyst Certification
              Course. Explore, discover, and excel on your terms. Elevate your
              skills, unleash your potential, and embrace a brighter
              future.Start learning today
            </p>

            <div className="row  d-flex justify-content-between  ">
              {" "}
              <div className="col-6" data-aos="fade-up-left">
                {" "}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleOpenModal}
                  style={{ width: "max-content", height: "max-content" }}
                >
                  Download Brochure
                </button>
              </div>
              {/* <div className="col-6 d-flex justify-content-end">
                {" "}
                <button
                  type="button"
                  className="btn btn-primary mb-5 d-block d-md-none  "
                  style={{
                    width: "max-content",
                    backgroundColor: "#00D95F",
                    borderRadius: "10px 0 10px 0px",
                  }}
                >
                  <img
                    height={"25px"}
                    width={"25px"}
                    src="./images/whatsapp.png"
                    alt=""
                    srcset=""
                  />
                  Chat with Us
                </button>
              </div> */}
            </div>
          </div>

          {/* <div className="col-12 col-md-4 col-xl-6    d-flex flex-column justify-content-end align-items-end px-2 px-sm-5 ">
            <button
              type="button"
              className="btn btn-primary mb-5 d-none d-md-block "
              style={{
                backgroundColor: "#00D95F",
                borderRadius: "30px 0 30px 0px",
              }}
            >
              <img
                height={"60px"}
                src="./images/whatsapp.png"
                alt=""
                srcset=""
              />{" "}
              Chat with Us
            </button>
          </div> */}
        </div>
      </div>

      {showModal && (
        <GoogleForm showModal={showModal} handleCloseModal={handleCloseModal} />
      )}
    </div>
  );
};

export default Home;
