import React from "react";

import TrainingCard from "./TrainingCard";

const Training = () => {
  const tools = [
    {
      name: "Classroom Training",
      localPrice: "₹ 63999",
      originalPrice: "₹ 56999",
      p1: "Faculty are Industrial based with thorough knowledge of the subject.",
      p2: "Avail 2 EMI without interest with us.",
    },
    {
      name: "Live Virtual Classroom",
      localPrice: "₹ 45999",
      originalPrice: "₹ 37999",
      p1: "Faculty are Industrial based with thorough knowledge of the subject.",
      p2: "Avail 2 EMI without interest with us.",
    },
  ];

  return (
    <div className="container-fluid">
      <div
        className="row px-1 px-sm-3 px-xl-5 pb-4 d-flex justify-content-center"
        // style={{ backgroundColor: "#008AD7" }}
      >
        <h1
          className="text-center heading1 mt-3 "
          style={{ color: "white", fontSize: "2.5rem" }}
        >
          Students Reviews
        </h1>

        <div className="row d-flex justify-content-center ">
          {tools.map((data, i) => (
            <div className="col-12 col-md-4">
              <TrainingCard data={data} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Training;
